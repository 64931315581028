import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_HELP_V2 } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/help-v2',
      component: component('views/app/AppView.vue'),
      meta: { app: APP_HELP_V2 },
      children: [
        {
          path: '/help-v2',
          name: 'AppHelpV2',
          beforeEnter: (to, from, next) => next({ name: 'AppHelpV2/ContactForm' }),
        },
        {
          path: 'contact-form',
          name: 'AppHelpV2/ContactForm',
          component: component('views/app/contact-form/ContactFormView.vue'),
          meta: { app: APP_HELP_V2 },
        },
      ],
    },
  ];
});
