import { EnterpriseProjectBuildingStatus, EnterpriseProjectType } from '@/types/iot-portal';

export const LiegenshaftStatusMappings = new Map([
  [
    EnterpriseProjectBuildingStatus.PROPERTY_CREATED,
    { value: EnterpriseProjectBuildingStatus.PROPERTY_CREATED, label: 'Liegenschaft erstellt' },
  ],
  [
    EnterpriseProjectBuildingStatus.PROPERTY_LIST_FINAL,
    { value: EnterpriseProjectBuildingStatus.PROPERTY_LIST_FINAL, label: 'Standortliste finalisiert' },
  ],
  [
    EnterpriseProjectBuildingStatus.ORDER_CONFIRMED,
    { value: EnterpriseProjectBuildingStatus.ORDER_CONFIRMED, label: 'Auftrag bestätigt' },
  ],
  [
    EnterpriseProjectBuildingStatus.INFORMATION_MISSING,
    { value: EnterpriseProjectBuildingStatus.INFORMATION_MISSING, label: 'fehlende Informationen' },
  ],
  [
    EnterpriseProjectBuildingStatus.IN_DEPLOYMENT,
    { value: EnterpriseProjectBuildingStatus.IN_DEPLOYMENT, label: 'im Rollout' },
  ],
  [
    EnterpriseProjectBuildingStatus.DEPLOYMENT_DONE,
    { value: EnterpriseProjectBuildingStatus.DEPLOYMENT_DONE, label: 'Rollout erledigt' },
  ],
  [EnterpriseProjectBuildingStatus.IN_QA, { value: EnterpriseProjectBuildingStatus.IN_QA, label: 'in QA' }],
  [
    EnterpriseProjectBuildingStatus.COMPLETED,
    { value: EnterpriseProjectBuildingStatus.COMPLETED, label: 'abgeschlossen' },
  ],
  [EnterpriseProjectBuildingStatus.CANCELLED, { value: EnterpriseProjectBuildingStatus.CANCELLED, label: 'storniert' }],
]);

export const EnterpriseProjectCopyOption: { value: string; label: string }[] = [
  { value: EnterpriseProjectType.NEW_ORDER, label: 'Neubestellungen' },
  { value: EnterpriseProjectType.PRICING_CHANGES, label: 'Preisänderung' },
  { value: EnterpriseProjectType.NEW_PRODUCT_NEW_USE_CASE, label: 'Produkterweiterung' },
  { value: EnterpriseProjectType.NEW_PRODUCT_ORIGINAL_USE_CASE, label: 'Produktänderung' },
];
