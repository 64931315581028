














































































































































import { defineComponent } from '@vue/composition-api';
import { mapActions, mapGetters, mapState } from 'vuex';
import Form from '@/features/ui/form/Form.global.vue';
import FormField from '@/features/ui/form/FormField.global.vue';
import { AddNewEnterpriseProject, AddNewEnterpriseProjectVariables } from './__generated__/AddNewEnterpriseProject';
import createProjectMutation from './add-project.gql';
import customerQuery from '../../../enterprise-project/customer-by-external-id.gql';
import {
  AppEnterpriseProjectCustomerByExternalIdQuery,
  AppEnterpriseProjectCustomerByExternalIdQueryVariables,
} from '../../../enterprise-project/__generated__/AppEnterpriseProjectCustomerByExternalIdQuery';
import { ApolloQueryResult } from 'apollo-client';
import getCustomerByName from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/getCustomerByName.gql';
import { GetCustomerByNameVariables } from '@/features/app-enterprise-project/views/enterprise-project-create/components/step1/__generated__/GetCustomerByName';
import InputText from '@/features/ui/inputs/InputText.global.vue';
import { InputValidationMixin } from '@/features/core/components/mixins/input-validation';
import FormMessage from '@/features/ui/form/FormMessage.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: { FormMessage, InputText, Form, FormField },
  mixins: [InputValidationMixin],
  props: {},
  data() {
    return {
      debounceTimer: null, // Timer for the debounce function
      debounceTimerCustomer: null,
      loadedExternalId: null, // The external id of the customer that was loaded on the name match
      searching: false,
      formNotSaved: false,
    };
  },
  computed: {
    ...mapGetters(['isCopy']),
    ...mapState({
      generalInformationCompleted: (state: any) => state.EnterpriseProjectCreateModule.generalInformationCompleted,
      generalInformationFormDisabled: (state: any) =>
        state.EnterpriseProjectCreateModule.generalInformationCompleted &&
        state.EnterpriseProjectCreateModule.numberOfNonEditableBuildings > 0,
      customerName: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.customerName,
      customerNumber: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.customerNumber,
      partnerName: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.partnerName,
      invoiceNumber: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.invoiceNumber,
      contractTerm: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.contractTerm,
      createNewInvoiceNumber: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.general.createNewInvoiceNumber,
      referenceToMainContract: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.general.referenceToMainContract,
      transferToMainContract: (state: any) =>
        state.EnterpriseProjectCreateModule.newProjectData.general.transferToMainContract,
      comment: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.general.comment,
      projectReference: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.projectReference,
    }),
    contractTermOptions() {
      const maxYear = 10; // Define the maximum number of years
      const options = [];
      for (let i = 1; i <= maxYear; i++) {
        options.push({
          value: i,
          label: `${i} Jahr${i === 1 ? '' : 'e'}`,
        });
      }
      return options;
    },
    showSavingWarningMessage() {
      return this.formNotSaved && this.projectReference;
    },
    formDisabled() {
      return this.generalInformationFormDisabled || this.searching;
    },
  },
  watch: {
    customerName: {
      handler() {
        this.updateParent(
          {
            customerName: this.customerName,
            customerNumber: this.customerNumber,
            partnerName: this.partnerName,
            invoiceNumber: this.invoiceNumber,
            contractTerm: this.contractTerm,
            createNewInvoiceNumber: this.createNewInvoiceNumber,
            referenceToMainContract: this.referenceToMainContract,
            transferToMainContract: this.transferToMainContract,
            comment: this.comment,
          },
          null,
        );
      },
      deep: true,
    },
  },
  async mounted() {
    await this.loadMatchingCustomers(this.customerName);
    // Call updateParent with initial form values
    this.updateParent(
      {
        customerName: this.customerName,
        customerNumber: this.customerNumber,
        partnerName: this.partnerName,
        invoiceNumber: this.invoiceNumber,
        contractTerm: this.contractTerm,
        createNewInvoiceNumber: this.createNewInvoiceNumber,
        referenceToMainContract: this.referenceToMainContract,
        transferToMainContract: this.transferToMainContract,
        comment: this.comment,
      },
      null,
    );
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    async updateProjectData(formData: any) {
      // Emit toggle-saving
      this.$emit('toggle-saving', true);
      // Only create new project if the project reference is not set
      if (!this.projectReference) {
        const result = await (
          this.$apollo.query({
            query: customerQuery,
            variables: {
              externalId: this.loadedExternalId ? this.loadedExternalId : formData.customerNumber,
            } as AppEnterpriseProjectCustomerByExternalIdQueryVariables,
          }) as Promise<ApolloQueryResult<AppEnterpriseProjectCustomerByExternalIdQuery>>
        ).catch((error) => {
          // TODO: Add Proper error handling with a toast and sending to a bug tracker service
          // eslint-disable-next-line no-console
          console.error('An error occured while fetching customer info from the file import:', error);
        });

        const customerData = result?.data?.customers?.first;

        // Call mutation to persist project to database
        const { data } = await this.$apollo.mutate({
          mutation: createProjectMutation,
          variables: {
            input: {
              customerId: customerData?.id,
              objectList: [],
              salesContactUserName: formData.partnerName ?? '',
              serviceProviderId: null,
            },
          } as AddNewEnterpriseProjectVariables,
        });
        const { createProject } = data as AddNewEnterpriseProject;
        formData.id = createProject.project.id;
        formData.projectReference = createProject.project.projectReference;
      }
      formData.general = {
        customerName: formData.customerName,
        customerNumber: this.loadedExternalId ? this.loadedExternalId : formData.customerNumber,
        partnerName: formData.partnerName,
        invoiceNumber: formData.invoiceNumber,
        contractTerm: formData.contractTerm,
        createNewInvoiceNumber: formData.createNewInvoiceNumber,
        referenceToMainContract: formData.referenceToMainContract,
        transferToMainContract: formData.transferToMainContract,
        comment: formData.comment,
      };
      await this.$store.dispatch('saveNewProjectData', formData);
      await this.$store.dispatch('setGeneralInformationCompleted', true);
      await this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: false });
      this.formNotSaved = false;
      this.$emit('toggle-saving', false);
      this.$emit('save-completed');
    },
    async onCustomerNameChange(customerName: string) {
      // Clear the previous timer if the user is still typing
      if (this.debounceTimerCustomer !== null) {
        clearTimeout(this.debounceTimerCustomer);
      }

      // Set a new timer to load matching customers after 300ms (reduced from 1000ms for better responsiveness)
      this.debounceTimerCustomer = window.setTimeout(async () => {
        try {
          if (customerName.length < 3) {
            // Optionally, you can clear or reset the matching customers if the input is too short
            // this.matchingCustomers = [];
            return;
          }

          // Set a loading state if you have one
          // this.isLoadingCustomers = true;

          await this.loadMatchingCustomers(customerName);
        } catch (error) {
          // Add toast saying that no existing customers were found
          await this.addToastMessages({
            duration: 1000,
            messages: [{ text: 'Keine Kunden gefunden', class: 'info' }],
          });
        }
      }, 300);
    },
    async loadMatchingCustomers(customerName: string) {
      this.searching = true;
      try {
        // Check if a customer exist with this name
        const { data } = await this.$apollo.query({
          query: getCustomerByName,
          variables: {
            customerName: customerName,
          } as GetCustomerByNameVariables,
        });
        if (data && data.customers.first && data.customers.first.name === customerName) {
          this.loadedExternalId = data.customers.first.externalId;
        } else {
          this.loadedExternalId = null;
        }
      } catch (e) {
        // We don't want to show the toast in the beginning
        if (this.formNotSaved) {
          await this.addToastMessages({
            duration: 1000,
            messages: [{ text: 'Keine Kunden gefunden', class: 'info' }],
          });
        }
      }
      this.searching = false;
    },

    // Method to expose the submit function to the parent
    triggerSubmit() {
      // Access the submit method using the ref
      const formRef = this.$refs.customerForm;
      if (formRef && formRef.submit) {
        formRef.submit();
      }
    },
    updateParent(formData: any, $event?: any, propertyName?: string) {
      if (propertyName === 'contractTerm') {
        formData[propertyName] = $event;
      } else if (propertyName && $event?.target) {
        formData[propertyName] = $event.target.value;
        this.formNotSaved = true;
      }
      this.$emit('update-parent', { formData, loadedExternalId: this.loadedExternalId });
    },
  },
});
