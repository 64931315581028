




















































































import { defineComponent, ref } from '@vue/composition-api';
import { EnterpriseProjectBuildingStatus, EnterpriseProjectStatus, TreeNodeClassification } from '@/types/iot-portal';
import { EnterpriseProjectUpdateObjectStatusMutationVariables } from '@/features/app-enterprise-project/views/enterprise-project-details/__generated__/EnterpriseProjectUpdateObjectStatusMutation';
import updateObjectStatusMutation from '@/features/app-enterprise-project/views/enterprise-project-details/update-object-status.gql';
import { getBuildingByName } from '@/features/core/store/page-modules/enterprise-project-details/property-building-edit-helpers';
import { Option } from '@/features/ui/inputs/model';
import { LiegenshaftStatusMappings } from '@/features/app-enterprise-project/views/enterprise-project-details/project-details-constants';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import PropertyEditModal from '@/features/app-enterprise-project/views/enterprise-project-details/components/PropertyEditModal.vue';
import { GetProjectDetailsQuery_projectDetails_items } from '@/features/app-enterprise-project/views/enterprise-project-details/__generated__/GetProjectDetailsQuery';
import GroupsMixin from '@/features/core/components/mixins/groups';
import { EnterpriseProjectServiceType } from '@/features/app-enterprise-project/enterprise-project-constants';
import { mapActions } from 'vuex';
import FormMessage from '@/features/ui/form/FormMessage.global.vue';
import Table from '@/features/ui/table/Table.global.vue';
import InputSelect from '@/features/ui/inputs/InputSelect.global.vue';
import Cell from '@/features/ui/layout/Cell.global.vue';

type StatusUpdateLog = {
  status: EnterpriseProjectBuildingStatus;
  id: string;
};

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: {
    PropertyEditModal,
    WizardButton,
    FormMessage,
    Table,
    InputSelect,
    Cell,
  },
  mixins: [GroupsMixin],
  props: {
    parentLoading: {
      type: Boolean,
      required: false,
    },
    projectObjectList: {
      type: Array,
      required: true,
    },
    projectDetails: {
      type: Object,
      required: true,
    },
    projectDraft: {
      type: Object,
      required: false,
    },
    projectReference: {
      type: String,
      required: true,
    },
  },
  setup() {
    // This is for the modal
    const propertyEditModal = ref(null);

    const openPropertyEditModal = (): void => {
      if (!propertyEditModal.value) {
        return;
      }
      (propertyEditModal.value as any).show();
    };

    return {
      propertyEditModal,
      openPropertyEditModal,
    };
  },
  data() {
    return {
      columns: [
        { name: 'buildingReferenceId', label: 'Liegenschafts ID' },
        { name: 'pdtpCCRNumber', label: 'PDTP/CCR-Nummer' },
        { name: 'address', label: 'Anschrift' },
        { name: 'buildingType', label: 'Art' },
        { name: 'numberOfApartments', label: 'WIE' },
        { name: 'gatewayIdentifier', label: 'GWID' },
        { name: 'mainService', label: 'Main Service' },
        { name: 'productType', label: 'Produkt' },
        { name: 'useCase', label: 'Anwendungsfälle' },
        { name: 'status', label: 'Status' },
        { name: 'lastUpdate', label: 'Letzte Änderung ' },
      ],
      statusMappings: LiegenshaftStatusMappings,
      tableLoading: false,
      statusUpdateLog: [] as StatusUpdateLog[],
      allowedCurrentStatusOptions: [
        // only allowed to manually update statuses that are on this list
        EnterpriseProjectBuildingStatus.IN_QA,
        EnterpriseProjectBuildingStatus.DEPLOYMENT_DONE,
      ] as EnterpriseProjectBuildingStatus[],
      allowedTargetStatusOptions: [
        // only allowed to manually update buildings to the statuses on this list
        EnterpriseProjectBuildingStatus.COMPLETED,
        EnterpriseProjectBuildingStatus.CANCELLED,
      ],
    };
  },
  computed: {
    EnterpriseProjectBuildingStatus() {
      return EnterpriseProjectBuildingStatus;
    },
    loading(): boolean {
      return this.tableLoading || this.parentLoading;
    },
  },
  mounted() {
    // Check if the user has the single liegenshaft role
    if (this.hasAppRole(this.appRoles.APP_ENTERPRISE_SINGLE_L_EDIT)) {
      // Add action column in second last place in columns
      this.columns.splice(this.columns.length - 1, 0, { name: 'actions', label: 'Aktionen' });
    }
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    statusOptions(item: GetProjectDetailsQuery_projectDetails_items): Option[] {
      const options = Array.from(this.statusMappings.values()) as Option<EnterpriseProjectBuildingStatus>[];

      if (this.allowedCurrentStatusOptions.includes(item.status)) {
        // Only display the current status and statuses that can be manually selected
        return options.filter(
          (option) => option.value == item.status || this.allowedTargetStatusOptions.includes(option.value),
        );
      }

      // Display all the statuses since the selector will be disabled in this case
      return options;
    },
    transformToDateOnly(date: string): string {
      if (date) {
        return new Date(date).toLocaleDateString('de-DE');
      }

      return '';
    },
    transformToTimeOnly(date: string): string {
      if (date) {
        return new Date(date).toLocaleTimeString('de-DE');
      }

      return '';
    },
    shouldShowStatusDropdown(buildingType: TreeNodeClassification): boolean {
      return buildingType === TreeNodeClassification.Liegenschaft;
    },
    isLGateway(identifier: string): boolean {
      const parts = identifier.split('_');
      return parts.length > 1 && parts[1].startsWith('L');
    },
    getGatewayIdentifier(gatewayIdentifier: any): string {
      return gatewayIdentifier?.identifier ? gatewayIdentifier.identifier : 'No Gateway Found';
    },
    goToGateway(gatewayIdentifier: any): void {
      if (gatewayIdentifier?.id) {
        this.$router.push({
          name: 'AppManager/TreeNode/Gateways/NewDetails',
          params: { treeNodeId: gatewayIdentifier.id, gatewayId: gatewayIdentifier.id },
        });
      }
    },
    getBuildingLetter(buildingType: string) {
      if (buildingType === TreeNodeClassification.Liegenschaft) {
        return 'L';
      }
      if (buildingType === TreeNodeClassification.Gebaude) {
        return 'G';
      }
    },
    getMainServiceText(serviceType: EnterpriseProjectServiceType): string {
      // NB: GK_HUB_G should be excluded on FE
      if (serviceType === EnterpriseProjectServiceType.GK_HUB_L_GDSP_MOBILFUNK) {
        return 'GK_HUB_L_GDSP_MOBILFUNK';
      }
      if (serviceType === EnterpriseProjectServiceType.GK_HUB_L_CABLE) {
        return 'GK_HUB_L_Kabel';
      }
      return '';
    },
    async onObjectStatusUpdate(value: string, row: any): Promise<void> {
      this.tableLoading = true;
      const status = value as EnterpriseProjectBuildingStatus;
      const id = row.id;

      const objectId = await this.updateObjectStatus({ input: { id, status } });

      if (objectId === id) {
        // update status in-memory to allow for logic changes without needing to reload page
        row.status = status;

        await this.addToastMessages({
          duration: 1000,
          messages: [{ text: 'Status erfolgreich aktualisiert', class: 'success' }],
        });
        // Check if the status is already in the log
        const index = this.statusUpdateLog.findIndex((update: StatusUpdateLog) => update.id === id);
        if (index > -1) {
          this.statusUpdateLog[index].status = status;
        } else {
          this.statusUpdateLog.push({ status, id });
        }
      } else {
        this.addToastMessages({
          duration: 1000,
          messages: [{ text: 'Status konnte nicht aktualisiert werden', class: 'error' }],
        });
      }
      this.tableLoading = false;
    },
    async updateObjectStatus(input: EnterpriseProjectUpdateObjectStatusMutationVariables): Promise<string> {
      const { data } = await this.$apollo.mutate({
        mutation: updateObjectStatusMutation,
        variables: { input: input.input },
      });

      if (!data) {
        throw new Error('Status kann nicht aktualisiert werden!');
      }

      return data.updateProjectObjectListItemStatus?.id;
    },
    editPropertyBuilding(selectedBuilding: GetProjectDetailsQuery_projectDetails_items) {
      // Get the building in the projectDraft object
      const building = getBuildingByName(this.projectDraft, selectedBuilding);
      this.$store.commit('setBuildingEditInformation', building);
      this.$store.commit('setBuildingReferenceId', selectedBuilding.buildingReferenceId);
      this.openPropertyEditModal();
    },
    shouldDisplayProjectEdit(): boolean {
      return this.project?.editOptions?.editable && this.project?.status !== EnterpriseProjectStatus.CANCELLED;
    },
    reloadData() {
      // Emit refresh function
      this.$emit('refresh');
    },
    canEditProjectItem(row: any): boolean {
      return (
        [
          EnterpriseProjectBuildingStatus.PROPERTY_CREATED,
          EnterpriseProjectBuildingStatus.PROPERTY_LIST_FINAL,
          EnterpriseProjectBuildingStatus.INFORMATION_MISSING,
        ].indexOf(this.getRowStatus(row)) > -1
      );
    },
    getRowStatus(row: any): string {
      const updatedStatus = this.statusUpdateLog.find((update: StatusUpdateLog) => update.id === row.id);
      if (updatedStatus) {
        return updatedStatus.status;
      }
      return row.status;
    },
    getUserName(user: any | null): string {
      if (!user) {
        return '';
      }
      return `${user.firstName} ${user.lastName}`;
    },
    disableStatusUpdate(row: any): boolean {
      return this.loading || !this.shouldDisplayProjectEdit || !this.allowedCurrentStatusOptions.includes(row.status);
    },
  },
});
