import { DollarApollo } from 'vue-apollo/types/vue-apollo';
import Vue from 'vue';
import { EnterpriseProjectObjectUseCase } from '@/features/app-enterprise-project/enterprise-project-constants';
import { Commit, Dispatch } from 'vuex';
import {
  EnterpriseProjectObjectProductOption,
  EnterpriseProjectServiceType,
  EnterpriseProjectType,
} from '@/types/iot-portal';

interface CustomerContact {
  title: string;
  firstName: string;
  surname: string;
  phoneNumber: string;
  email: string;
}

export type BuildingDetails = {
  invoiceName: string | null;
  serviceLevel: string | null;
  preInspection: boolean | null;
};

export type BuildingInformation = {
  title: string;
  firstName: string;
  surname: string;
  phoneNumber: string;
  email: string;
};
export type ProjectBuilding = {
  id: number;
  buildingId: number;
  name: string;
  cityAddress: string;
  houseAddress: number;
  houseAddressSuffix?: string;
  streetAddress: string;
  postCodeAddress: string;
  locationLongitude: number;
  locationLatitude: number;
  numberOfApartments: number;
  wieNumber?: string;
  liegenshaft: boolean;
  liegenshaftInformation?: BuildingInformation;
  // To keep track of the completion of the building information
  liegenshaftInformationCompleted?: boolean;
  parentProperty?: number | null;
  mainService?: EnterpriseProjectServiceType | null;
  products?: LiegenschaftProduct[];
  useCases?: LiegenschaftUseCase[];
  hasElevator: boolean;
  invoiceName?: string | null;
  serviceLevel?: string | null;
  installationStartDate?: string | null;
  preInspection?: boolean | null;
  includesComments?: boolean;
  comment: string | null;
  objectId?: string;
};

export enum EnterpriseProjectObjectProduct {
  INFRASTRUCTURE_VARIANT = 'INFRASTRUCTURE_VARIANT',
  IW_CLOUD = 'IW_CLOUD',
}

export enum InfrastructureVariantType {
  START = 'Start',
  BASIS = 'Basis',
  'BASIS+' = 'Basis+',
  PRO = 'Pro',
}

export enum IWCloudType {
  STANDARD = 'Standard',
  PRO = 'Pro',
}

export interface CreatedBuildingMeta {
  buildingReferenceId: string;
}

export interface EditOptions {
  editableBuildings: Map<string, CreatedBuildingMeta>;
  buildingChangeMode: boolean;
  buildingsCreated: boolean;
  editable: boolean;
  projectDownloaded: boolean;
  numberOfNonEditableBuildings: number;
}

export interface LiegenschaftProduct {
  monthlyPriceInEuros?: number | null;
  oneTimePriceInEuros?: number | null;
  productType?: EnterpriseProjectObjectProduct;
  option?: InfrastructureVariantType | EnterpriseProjectObjectProductOption | IWCloudType | null;
}

export interface LiegenschaftUseCase {
  label?: string | null;
  title?: string | null;
  checked?: boolean | null;
  monthlyPriceInEuros?: number | null;
  oneTimePriceInEuros?: number | null;
  useCaseType?: EnterpriseProjectObjectUseCase | null;
}

// Define the type for the state
export type NewProjectData = {
  id: string | null;
  projectReference: string | null;
  parentProjectId: string | null;
  projectType: EnterpriseProjectType;
  general: NewProjectGeneralData;
  customerInfo: CustomerInfo;
  buildings: ProjectBuilding[];
};

export type NewProjectGeneralData = {
  customerName: string | null;
  customerNumber: string | null;
  partnerName: string | null;
  invoiceNumber: string | null;
  contractTerm: number | null;
  createNewInvoiceNumber: boolean;
  referenceToMainContract: boolean;
  transferToMainContract: boolean;
  comment: string | null;
};
// Define the state type
export type ProjectCreateState = {
  newProjectData: NewProjectData;
  activeStep2BuildingId: number;
  tab: 'step1' | 'step2' | 'step3' | 'preview';
  saving: boolean;
  objectToSave?: any;
  generalInformationCompleted: boolean;
  buildingsImportCompleted: boolean;
  propertyInformationCompleted: boolean;
  propertyAssociationCompleted: boolean;
  projectId: string | null;
  // Will keep track of which buildings are selected by used the building ids as the index
  selectedBuildingsForContactInformation: boolean[];
  // buildings that are already created are still editable: Map< building id, meta>
  editableBuildings?: Map<string, CreatedBuildingMeta>;
  // Whether the project is being edited in draft mode (false) or there are buildings already created (true)
  buildingChangeMode?: boolean;
  // building reference IDs that need to be invalidated to recreate the buildings
  buildingChangeList?: Set<string>;
  numberOfNonEditableBuildings?: number;
  buildingsCreated?: boolean;
  originalSaveObject?: (DatabaseProjectDraft & ProjectDraftCompleted & EditOptions) | null;
  allLiegenshaftInformationCompleted?: boolean;
  associatedBuildings: number;
  // Building information form validity
  buildingInformationFormValid: boolean;
  // This is to indicate if the current project is a copy
  originalMainBuildingIds: number[];
  originalBuildingIds: number[];
  omittedBuildingIds: number[];
  // For project changes so we can keep track of the changes
  projectCopyChangesId: string | null;
  commentBuildingMap: boolean[];
  commentTabActiveBuildingId: number | null;
};

export interface ProjectDraftMainBuilding {
  id: number;
  name: string;
  cityAddress: string;
  houseAddress: number;
  houseAddressSuffix?: string;
  streetAddress: string;
  postCodeAddress: string;
  locationLongitude: number;
  locationLatitude: number;
  numberOfApartments: number;
  customizedId?: string;
  accessType?: string;
  productType?: string;
  mainService?: EnterpriseProjectServiceType | null;
  products?: LiegenschaftProduct[];
  useCases?: LiegenschaftUseCase[];
  customerContact?: CustomerContact;
  invoiceName?: string | null;
  serviceLevel?: string | null;
  installationStartDate?: string | null;
  preInspection?: boolean | null;
  wieNumber?: string;
  hasElevator: boolean;
  includesComments: boolean;
  comment: string | null;
  objectId?: string;
}

export interface ProjectDraftBuilding {
  id: number;
  name: string;
  mainBuildingId: number | null;
  cityAddress: string;
  houseAddress: number;
  houseAddressSuffix?: string;
  streetAddress: string;
  postCodeAddress: string;
  locationLongitude: number;
  locationLatitude: number;
  numberOfApartments: number;
  customizedId?: string;
  productType?: string;
  accessType?: string;
  mainService?: string;
  hasElevator: boolean;
  comment: string | null;
  objectId?: string;
}

export type ProjectDraftCompleted = {
  generalInformationCompleted: boolean;
  buildingsImportCompleted: boolean;
  propertyInformationCompleted: boolean;
  propertyAssociationCompleted: boolean;
  originalMainBuildingIds: number[];
  originalBuildingIds: number[];
  omittedBuildingIds: number[];
};

export type CustomerInfo = {
  id?: string | null;
  customerName: string | null;
  customerNumber: string | null;
  contactPerson: string | null;
  invoiceNumber: string | null;
  contractTerm: number | null;
  createNewInvoiceNumber: boolean;
  referenceToMainContract: boolean;
  transferToMainContract: boolean;
  comment: string | null;
};

export interface DatabaseProjectDraft {
  id: string;
  projectReference: string;
  projectType: EnterpriseProjectType;
  parentProjectId: string | null;
  general: {
    customerName: string | null;
    customerNumber: string | null;
    partnerName: string | null;
    invoiceNumber: string | null;
    contractTerm: number | null;
    createNewInvoiceNumber: boolean;
    referenceToMainContract: boolean;
    transferToMainContract: boolean;
    comment: string | null;
  };
  customerInfo: CustomerInfo;
  mainBuildings: ProjectDraftMainBuilding[];
  buildings: ProjectDraftBuilding[];
}

export type SaveToDBParams = {
  apolloClient: DollarApollo<Vue>;
  finalSave: boolean;
};

export interface SetBuildingInformationPayload {
  buildingInformation: BuildingInformation;
  buildingId: number;
}

export interface EnterpriseActions {
  saveNewBuildingData(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    buildings: ProjectBuilding[],
  ): Promise<void>;
}

export type NewProductSelection = {
  mainService: EnterpriseProjectServiceType | null;
  infrastructureVariant: EnterpriseProjectObjectProductOption | null;
  heating:
    | EnterpriseProjectObjectUseCase.HEATING_MONITORING
    | EnterpriseProjectObjectUseCase.HEATING_PROFESSIONAL
    | null;
  elevator:
    | EnterpriseProjectObjectUseCase.ELEVATOR_MONITORING
    | EnterpriseProjectObjectUseCase.SMART_ELEVATOR_MAINTENANCE
    | null;
};
