













































import { defineComponent } from '@vue/composition-api';
import { mapState, mapActions, mapGetters } from 'vuex';
import InputText from '@/features/ui/inputs/InputText.global.vue';
import { InputValidationMixin } from '@/features/core/components/mixins/input-validation';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import InputCheckbox from '@/features/ui/inputs/InputCheckbox.global.vue';

export default defineComponent<any, Record<string, unknown>, any, any, any>({
  components: {
    InputCheckbox,
    WizardButton,
    InputText,
  },
  mixins: [InputValidationMixin],
  data() {
    return {
      search: '',
      selectedBuildings: [],
      showSelectedBuildings: true,
    };
  },
  computed: {
    ...mapGetters(['currentTab', 'buildingsWithElevators', 'commentBuildingMap']),
    ...mapState({
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
      buildingsCreated: (state: any) => state.EnterpriseProjectCreateModule.buildingsCreated,
      editableBuildings: (state: any) => state.EnterpriseProjectCreateModule.editableBuildings,
      commentTabActiveBuildingId: (state: any) => state.EnterpriseProjectCreateModule.commentTabActiveBuildingId,
    }),
    filteredBuildings() {
      const initialFilteredBuildings = this.buildings.filter(
        (building: any) => building.includesComments && building.liegenshaft,
      );
      return initialFilteredBuildings.filter((building: any) =>
        building.name.toLowerCase().includes(this.search.toLowerCase()),
      );
    },
  },
  watch: {
    commentBuildingMap: {
      handler() {
        this.refreshList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['setActiveStep2BuildingId']),
    setActiveBuilding(id: number) {
      this.setActiveStep2BuildingId(id);
      this.$store.commit('setCommentTabActiveBuildingId', id);
    },
    validateBuildingInformationForm(building: ProjectBuilding) {
      return (
        this.validateEmail(building.liegenshaftInformation?.email) &&
        building.liegenshaftInformation?.phoneNumber &&
        building.liegenshaftInformation?.firstName &&
        building.liegenshaftInformation?.surname &&
        building.liegenshaftInformation?.title
      );
    },
    // eslint-disable-next-line complexity
    getListItemSubText(building: ProjectBuilding): string {
      if (this.commentBuildingMap[building.buildingId]) {
        return 'ausgewählt';
      }
      if (building.buildingId === this.activeStep2BuildingId) {
        return 'in Bearbeitung';
      }
      if (building.comment) {
        return 'ändern';
      }
      if (this.buildingsCreated && !this.editableBuildings.has(`${building.buildingId}`)) {
        // uneditable building in a project where buildings are already created
        return 'nicht editierbar';
      }
      return 'offen';
    },
    selectAllFilteredBuildings() {
      this.showSelectedBuildings = false;
      const localSelectedBuildings = this.selectedBuildings;
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        localSelectedBuildings[this.filteredBuildings[i].buildingId] = true;
        this.$store.commit('selectMainAndSubBuildingsForComments', {
          buildingId: this.filteredBuildings[i].buildingId,
          value: true,
        });
      }
      // Emit event to parent component
      this.$emit('active-building-updated');
      this.selectedBuildings = localSelectedBuildings;
      this.showSelectedBuildings = true;
    },
    deselectAllFilteredBuildings() {
      this.showSelectedBuildings = false;
      const localSelectedBuildings = this.selectedBuildings;
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        localSelectedBuildings[this.filteredBuildings[i].buildingId] = false;
        this.$store.commit('selectMainAndSubBuildingsForComments', {
          buildingId: this.filteredBuildings[i].buildingId,
          value: false,
        });
      }
      // Emit event to parent component
      this.$emit('active-building-updated');
      this.selectedBuildings = localSelectedBuildings;
      this.showSelectedBuildings = true;
    },
    onBuildingClick(event: any, buildingId: number) {
      let newValue = false;
      if (event.target?.checked) {
        newValue = event.target.checked;
      }
      this.showSelectedBuildings = false;
      this.$store.commit('selectMainAndSubBuildingsForComments', { buildingId, value: newValue });
      this.showSelectedBuildings = true;
      // Emit event to parent component
      this.$emit('active-building-updated');
    },
    refreshList() {
      this.showSelectedBuildings = false;
      this.showSelectedBuildings = true;
    },
  },
});
