







































































































































































import { defineComponent } from '@vue/composition-api';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  aufzugUseCaseOptions,
  heizungUseCaseOptions,
  infrastructureOptions,
  mainServiceOptions,
} from '@/features/app-enterprise-project/enterprise-project-constants';
import InputSelect from '@/features/ui/inputs/InputSelect.global.vue';
import { EnterpriseProjectType } from '@/types/iot-portal';
import saveProjectCopyChanges from '@/features/app-enterprise-project/views/enterprise-project-details/save-project-copy-changes.gql';
import { SaveProjectCopyChangesVariables } from '@/features/app-enterprise-project/views/enterprise-project-details/__generated__/SaveProjectCopyChanges';
import updateProject from '@/features/app-enterprise-project/views/enterprise-project-details/update-project.gql';

export default defineComponent<any, Record<string, unknown>, any, any>({
  components: { InputSelect, WizardButton },
  props: {
    externalLoading: Boolean,
  },
  data() {
    return {
      saving: false,
      openModal: false,
      openChangesModal: false,
      openInstallationModal: false,
      columns: [
        { name: 'product', label: 'Product' },
        { name: 'pdtpCCRNumber', label: 'PDTP/CCR-Nummer' },
        { name: 'address', label: 'Anschrift' },
      ],
      heatingOptions: heizungUseCaseOptions,
      elevatorOptions: aufzugUseCaseOptions,
      mainServiceOptions,
      infrastructureOptions,
      oldProductSelection: {
        mainService: null,
        infrastructureVariant: null,
        heating: null,
        elevator: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'allBuildingsHaveElevator',
      'newProductSelection',
      'projectType',
      'projectId',
      'projectCopyChangesId',
      'allMainBuildingProductInformationIsValid',
    ]),
    ...mapState({
      tab: (state: any) => state.EnterpriseProjectCreateModule.tab,
      projectReference: (state: any) => state.EnterpriseProjectCreateModule?.newProjectData?.projectReference,
    }),
    buttonDisabled(): boolean {
      if (this.tab !== 'elevatorSelection') {
        return !this.allMainBuildingProductInformationIsValid;
      }
      return !this.allBuildingsHaveElevator || !this.allMainBuildingProductInformationIsValid;
    },
  },
  methods: {
    ...mapActions({
      addToastMessages: 'ADD_TOAST_MESSAGES',
    }),
    async handleConfirmModal() {
      if (this.projectType === EnterpriseProjectType.NEW_PRODUCT_ORIGINAL_USE_CASE) {
        this.openChangesModal = true;
      } else {
        this.openModal = true;
      }
    },
    async saveProject() {
      this.saving = true;
      // Save the project changes if correct type
      if (this.projectType === EnterpriseProjectType.NEW_PRODUCT_ORIGINAL_USE_CASE) {
        const changes: SaveProjectCopyChangesVariables = {
          projectId: this.projectId,
          oldMainService: this.oldProductSelection.mainService,
          newMainService: this.newProductSelection.mainService,
          oldInfrastructure: this.oldProductSelection.infrastructureVariant,
          newInfrastructure: this.newProductSelection.infrastructureVariant,
          oldHeating: this.oldProductSelection.heating,
          newHeating: this.newProductSelection.heating,
          oldElevator: this.oldProductSelection.elevator,
          newElevator: this.newProductSelection.elevator,
        };
        if (this.projectCopyChangesId) {
          changes.id = this.projectCopyChangesId;
        }
        const { data } = await this.$apollo.mutate({
          mutation: saveProjectCopyChanges,
          variables: changes,
        });

        if (!data) {
          throw new Error('Der Status kann nicht aktualisiert werden!');
          this.saving = false;
        }
      }
      // After saving changes, check if we should show installation modal
      if (this.projectType !== EnterpriseProjectType.ORIGINAL) {
        this.openModal = false;
        this.openChangesModal = false;
        this.openInstallationModal = true;
        this.saving = false;
        return;
      }

      // If not showing installation modal, continue with original flow
      await this.finalizeProject();
    },
    async handleInstallationResponse(installationRequired: boolean) {
      this.saving = true;
      const response = await this.$apollo.mutate({
        mutation: updateProject,
        variables: {
          input: {
            projectReference: this.projectReference,
            installationRequired,
          },
        },
      });

      await this.finalizeProject();
    },
    async finalizeProject() {
      await this.$store.dispatch('saveToDB', { apolloClient: this.$apollo, finalSave: true });
      await this.addToastMessages({
        duration: 1000,
        messages: [{ text: 'Projekt wurde angelegt', class: 'success' }],
      });

      this.openInstallationModal = false;
      this.saving = false;

      if (this.$route.name !== 'AppEnterpriseProject/ProjectDetails') {
        await this.$router.push({
          name: 'AppEnterpriseProject/ProjectDetails',
          params: {
            projectReference: this.projectReference,
          },
        });
      }

      this.$emit('hide');
    },
  },
});
