


















































































import { Component, Mixins } from 'vue-property-decorator';
import sendHelpMailMutation from './send-help-mail.gql';
import { AppHelpGetMyEmailV2 } from './__generated__/AppHelpGetMyEmailV2';
import {
  AppHelpSendHelpMailMutationV2,
  AppHelpSendHelpMailMutationV2Variables,
} from './__generated__/AppHelpSendHelpMailMutationV2';
import getMyEmail from './get-my-email.gql';
import ContainerMixin from '@/features/core/components/mixins/container';
import { App } from '@/features/core/model';
import propertiesCountQuery from './properties-count.gql';
import { RootGetter } from '@/features/core/store';

@Component({
  apollo: {
    me: {
      query: getMyEmail,
    },
    properties: {
      query: propertiesCountQuery,
    },
  },
  data() {
    return { me: undefined, properties: undefined };
  },
})
export default class ContactFormView extends Mixins(ContainerMixin) {
  @RootGetter
  private readonly appMap!: Record<string, App>;

  private readonly me?: AppHelpGetMyEmailV2['me'];

  private readonly typeOptions = [
    'Vertriebliche Anfrage',
    'Frage zum Webportal Allgemein',
    'Frage zu einer Anwendung',
    'Störung',
    'Anregung',
  ];

  private showSuccessMessage = false;

  private async sendHelpMail(input: AppHelpSendHelpMailMutationV2Variables['input']): Promise<void> {
    this.showSuccessMessage = false;
    await this.$apollo.mutate<AppHelpSendHelpMailMutationV2, AppHelpSendHelpMailMutationV2Variables>({
      mutation: sendHelpMailMutation,
      variables: { input },
    });
    this.showSuccessMessage = true;
  }
}
