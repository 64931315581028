import { ActionContext, GetterTree, Module, MutationTree } from 'vuex';
import { RootStore } from '@/features/core/store';
import { HeatingSystemSummary } from '@/features/domain-ui/heating-system-list-table/model';
import { ORIGIN_ENDPOINT } from '@/env';
import { keycloak } from '@/keycloak';

export type HeatingSystem = HeatingSystemSummary & {
  active: boolean;
  metadata?: HeatingSystemMetadata;
};
export type HeatingSystemComponent = {
  label: string;
  value: string;
  active: boolean;
  type: string;
};
export type HeatingSystemMetadata = {
  // Kessel
  boiler: {
    condensing: boolean | null;
    wall_mounted: boolean | null;
    return_temp_uplift: boolean | null;
    safety_temp_guard: number | null;
    safety_temp_limiter: number | null;
    cascade: boolean | null;
    is_modulating: boolean | null;
    has_own_pump: boolean | null;
    has_regulating_valve: boolean | null;
    has_check_valve: boolean | null;
    system_separation: boolean | null;
    power_max_out: number | null;
    power_min_out: number | null;
  };
  // Warmwasserbereitung
  waterHeating: {
    type_of_pwh: string | null;
    potable_water_target_temp: number | null;
    potable_water_temperature_tolerance: number | null;
    circulation_target_temp: number | null;
    circulation_temperature_tolerance: number | null;
  };
  // Pufferspeicher
  buffer: {
    type: BufferTypes;
    upper_limit: number | null;
    lower_limit: number | null;
    prim_port_energy_input: boolean | null;
    sec_port_energy_input: boolean | null;
    tert_port_energy_input: boolean | null;
    quart_port_energy_input: boolean | null;
    two_sensors_controlled: boolean | null;
  };
  // Heizkreis
  heatingCircuit: {
    control_parameter: {
      type: HeatingCircuitTypes | null;
      summer_shutdown_temp: number | null;
      nightly_reduced_temperature: number | null;
      minimum_temperature: number | null;
      maximum_temperature: number | null;
      supply_potable_water_heating: boolean | null;
      room_target_temperature: number | null;
      offset: number | null;
      slope: number | null;
      target_temperature: number | null;
      env_flow_temp_points: TemperatureEntry[] | null;
    };
    radiator_type: HeatingCircuitRadiatorTypes;
    heating_circuit_building_year: number | null;
    temperature_control_hardware: HeatingCircuitTemperatureControlHardware;
    execute_oscillation_analysis: boolean;
  };
  // Fernwärme
  teleHeating: {
    system_separation: boolean | null;
    teleheating_type: TeleHeatingType;
    safety_temp_guard: number | null;
    safety_temp_limiter: number | null;
    temperature_control_type: string | null;
    flow_temperature_control_parameter: TemperatureControlParameter;
    return_temperature_control_parameter: TemperatureControlParameter;
  };
};

export type HeatingSystemMetadataKey = HeatingSystemMetadata[keyof HeatingSystemMetadata];

export type TemperatureControlParameter = {
  type: string | null;
  target_temperature: number | null;
  points: TemperatureEntry[] | null;
};

export enum TeleHeatingType {
  SINGLE = 'single',
  DUAL = 'dual',
  DIRECT = 'direct',
}

export enum WaterHeatingTypes {
  FRESH_WATER_STATION = 'fresh_water_station',
  BUFFER_CHARGING_SYSTEM = 'buffer_charging_system',
  BUFFER_HEATING_COIL_SYSTEM = 'buffer_heating_coil_system',
}

export enum BufferTypes {
  SOLAR = 'solar',
  HEAT_ENERGY_STORAGE = 'heat_energy_storage',
  POTABLE_WATER = 'potable_water',
}

export enum HeatingCircuitTypes {
  AUTOMATIC = 'automatic',
  PARAMETERIZED_CURVE = 'parameterized_curve',
  POINTED_CURVE = 'pointed_curve',
  CONSTANT_TEMPERATURE = 'constant_temperature',
}

export enum HeatingCircuitTemperatureControlHardware {
  FLOW_CTRL_VALVE = 'flow_ctrl_valve',
  DIFF_PRESSURE_CTRL = 'diff_pressure_ctrl',
  FLOW_DIVERTED = 'flow_diverted',
  INJECTION_UNIT = 'injection_unit',
  INLINE_VALVE = 'inline_valve',
  DOUBLE_ADMIX = 'double_admix',
  SINGLE_ADMIX = 'single_admix',
  UNMIXED = 'unmixed',
}

export enum HeatingCircuitRadiatorTypes {
  CONVENTIONAL_RADITOR = 'conventional radiator',
  VENTILATION = 'ventilation',
  UNDERFLOOR_HEATING = 'underfloor heating',
}

export type MetadataPayload = {
  spotId: string;
  heatingSystemId: string;
  componentType: string;
  metadata: HeatingSystemMetadataKey;
};

export type CreateConnectionPayload = {
  customerId: string;
};

export type UpdateConnectionPayload = {
  customerId: string;
  enabled: boolean;
  consent: boolean;
  treeNodeIds: string[];
};

export type MetadataKeys = HeatingSystemMetadata['boiler'] &
  HeatingSystemMetadata['waterHeating'] &
  HeatingSystemMetadata['buffer'] &
  HeatingSystemMetadata['heatingCircuit'] &
  HeatingSystemMetadata['teleHeating'];

export type TemperatureEntry = {
  environment: number | null; // Umgebungstemperatur
  target: number | null; // Soll-Temperatur
};

export type HeatingSystemMetadataResponse = {
  componentType: string;
  heatingsystemId: string;
  metadata: JSONObject;
  spotId: string;
};

export type HeatingSystemsMetadataState = {
  openModal: boolean;
  openApplySettings: boolean;
  openAuditView: boolean;
  heatingSystems: HeatingSystem[];
  heatingSystemsComponents: HeatingSystemComponent[];
  formData: HeatingSystemMetadata | null;
  heatingSystemsMetadata: HeatingSystemMetadataResponse[];
  idsToApplySettings: string[];
};

const checkPayload = (payload: MetadataPayload): MetadataPayload => {
  if (payload.componentType === 'WARM_HEATING') {
    payload.componentType = 'PORTABLE_WATER_HEATING';
  }

  return payload;
};

const state: HeatingSystemsMetadataState = {
  openModal: false,
  openApplySettings: false,
  openAuditView: false,
  heatingSystems: [],
  heatingSystemsComponents: [],
  formData: null,
  heatingSystemsMetadata: [],
  idsToApplySettings: [],
};
const getters: GetterTree<HeatingSystemsMetadataState, RootStore> = {
  openModal: (state) => state.openModal,
  openApplySettings: (state) => state.openApplySettings,
  openAuditView: (state) => state.openAuditView,
  heatingSystems: (state) => state.heatingSystems,
  heatingSystemsComponents: (state) => state.heatingSystemsComponents,
  activeHeatingSystem: (state) => state.heatingSystems.find((item) => item.active === true),
  activeHeatingSystemComponent: (state) => state.heatingSystemsComponents.find((item) => item.active === true),
  formData: (state) => state.formData,
  heatingSystemsMetadata: (state) => state.heatingSystemsMetadata,
  idsToApplySettings: (state) => state.idsToApplySettings,
};
const mutations: MutationTree<HeatingSystemsMetadataState> = {
  openModal(state, value: boolean) {
    state.openModal = value;
  },
  openApplySettings(state, value: boolean) {
    state.openApplySettings = value;
  },
  toggleAuditView(state) {
    state.openAuditView = !state.openAuditView;
  },
  setHeatingSystems(state, value: HeatingSystem[]) {
    state.heatingSystems = value;
  },
  setHeatingSystemsComponents(state, value: HeatingSystemComponent[]) {
    state.heatingSystemsComponents = value;
  },
  setFormData(state, value: HeatingSystemMetadata) {
    state.formData = value;
  },
  setHeatingSystemMetadata(state, value: HeatingSystemMetadataResponse[]) {
    state.heatingSystemsMetadata = value;
  },
  setIdsToApplySettings(state, value: string[]) {
    state.idsToApplySettings = value;
  },
};
const actions = {
  async getEnerIQConnection(
    { commit }: ActionContext<HeatingSystemsMetadataState, HeatingSystemsMetadataState>,
    customerId: string,
  ): Promise<Response> {
    return await fetch(`${ORIGIN_ENDPOINT}/api/v1/daedalus-connector/enerIqConnection?customerId=${customerId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${keycloak?.token ?? ''}`,
      },
    });
  },
  async createEnerIQConnection(
    { commit }: ActionContext<HeatingSystemsMetadataState, HeatingSystemsMetadataState>,
    payload: CreateConnectionPayload,
  ): Promise<Response> {
    return await fetch(`${ORIGIN_ENDPOINT}/api/v1/daedalus-connector/enerIqConnection`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${keycloak?.token ?? ''}`,
      },
      body: JSON.stringify(payload),
    });
  },
  async updateEnerIQConnection(
    { commit }: ActionContext<HeatingSystemsMetadataState, HeatingSystemsMetadataState>,
    payload: UpdateConnectionPayload,
  ): Promise<Response> {
    return await fetch(
      `${ORIGIN_ENDPOINT}/api/v1/daedalus-connector/enerIqConnection?customerId=${payload.customerId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${keycloak?.token ?? ''}`,
        },
        body: JSON.stringify({
          enabled: payload.enabled,
          consent: payload.consent,
          treeNodeIds: payload.treeNodeIds,
        }),
      },
    );
  },
  async deleteEnerIQConnection(
    { commit }: ActionContext<HeatingSystemsMetadataState, HeatingSystemsMetadataState>,
    customerId: string,
  ): Promise<Response> {
    return await fetch(`${ORIGIN_ENDPOINT}/api/v1/daedalus-connector/enerIqConnection?customerId=${customerId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${keycloak?.token ?? ''}`,
      },
    });
  },
  async addHeatingSystemMetadata(
    { commit }: ActionContext<HeatingSystemsMetadataState, HeatingSystemsMetadataState>,
    payload: MetadataPayload,
  ): Promise<Response> {
    return await fetch(`${ORIGIN_ENDPOINT}/api/v1/daedalus-connector/metadata`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${keycloak?.token ?? ''}`,
      },
      body: JSON.stringify(checkPayload(payload)),
    });
  },
  async updateHeatingSystemMetadata(
    { commit }: ActionContext<HeatingSystemsMetadataState, HeatingSystemsMetadataState>,
    payload: MetadataPayload,
  ): Promise<Response> {
    return await fetch(`${ORIGIN_ENDPOINT}/api/v1/daedalus-connector/metadata?spotId=${payload.spotId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${keycloak?.token ?? ''}`,
      },
      body: JSON.stringify(checkPayload(payload)),
    });
  },
  async getHeatingSystemMetadata(
    { commit }: ActionContext<HeatingSystemsMetadataState, HeatingSystemsMetadataState>,
    id: string,
  ): Promise<Response> {
    return await fetch(`${ORIGIN_ENDPOINT}/api/v1/daedalus-connector/metadata?heatingSystemId=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${keycloak?.token ?? ''}`,
      },
    });
  },
  async getAllHeatingSystemMetadata({
    commit,
  }: ActionContext<HeatingSystemsMetadataState, HeatingSystemsMetadataState>): Promise<Response> {
    return await fetch(`${ORIGIN_ENDPOINT}/api/v1/daedalus-connector/metadataList`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${keycloak?.token ?? ''}`,
      },
    });
  },
};

export const HeatingSystemsMetadataModule: Module<HeatingSystemsMetadataState, any> = {
  state,
  getters,
  mutations,
  actions,
};
