






























































import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { ChartStyle, LineChartType } from '@/components/charts/model';
import { isBooleanMetric, formatMetricValue } from '@/features/core/util/metric-formatters';
import { ChartOptions } from '@/components/charts/echarts/model';
import { INPUT_DATE_PICKER_MODE_META } from '@/features/ui/inputs/model';
import moment, { Moment } from 'moment';
import { MetricResolutionAggregator } from '@/types/iot-portal';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { CoreSpotMetricsPanelControlSpotQuery } from '@/features/core/components/spot-metrics-panel-control/__generated__/CoreSpotMetricsPanelControlSpotQuery';

export default defineComponent({
  mixins: [DeviceRoleMapMixin],
  data() {
    return {
      chartStyle: ChartStyle.LINE,
      chartType: LineChartType.ECHARTS,
      startDate: new Date(),
      stopDate: new Date(),
      aggregationInterval: null,
      spot: null as CoreSpotMetricsPanelControlSpotQuery['spot'] | null,
      aggregator: null as MetricResolutionAggregator | null,
      chartOptions: {
        legend: { bottom: 60, padding: [0, 0, 0, 0] },
        grid: {
          bottom: 150,
        },
      } as ChartOptions,
      metricData: [],
      metricNames: ['temperature1', 'temperature2', 'outsideTemperature'], // TODO: get from api
    };
  },
  computed: {
    ...mapGetters(['showAdvancedViewGraph']),
    yAxisDimensions(): Array<string | number> {
      if (this.chartStyle === ChartStyle.STEP) {
        return ['0', '1'];
      }
      return [];
    },
    inputCellBasis(): number {
      return window.innerWidth < 1920 ? 0.5 : 0.25;
    },
    datePickerStartAttrs(): JSONObject {
      const attrs: JSONObject = {};

      const mode =
        this.aggregationInterval === 'P1M' ? INPUT_DATE_PICKER_MODE_META.MONTH : INPUT_DATE_PICKER_MODE_META.DAY;

      attrs.disabledDates = {
        from:
          this.stopDate && this.aggregationInterval !== 'P1W'
            ? moment(this.stopDate).subtract(1, mode.duration).toDate()
            : this.now().toDate(),
        to: undefined,
      };

      // enable only month view
      if (this.aggregationInterval === 'P1M') {
        attrs.minimumView = mode.duration;
        attrs.maximumView = mode.duration;
      }

      return attrs;
    },
    datePickerStopAttrs(): JSONObject {
      const attrs: JSONObject = {};
      const mode =
        this.aggregationInterval === 'P1M' ? INPUT_DATE_PICKER_MODE_META.MONTH : INPUT_DATE_PICKER_MODE_META.DAY;

      const datePickerStartDate = this.startDate ? moment(this.startDate).toDate() : undefined;

      attrs.disabledDates = {
        from: this.now().add(1, mode.duration).toDate(),
        to:
          this.aggregationInterval === 'P1M'
            ? moment(datePickerStartDate).add(1, mode.duration).toDate()
            : datePickerStartDate,
      };

      // enable only month view
      if (mode !== INPUT_DATE_PICKER_MODE_META.DAY) {
        attrs.minimumView = mode.duration;
        attrs.maximumView = mode.duration;
      }

      return attrs;
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('showAdvancedViewGraph', false);
    },
    yAxisLabelFormatter(value: string | number): string {
      if (this.chartStyle === ChartStyle.STEP && this.metricNames.some(isBooleanMetric)) {
        return value === '0' ? 'no/off' : 'yes/on';
      }
      return this.metricNames.length === 0 ? `${value}` : `${this.formatValue(value, this.metricNames[0]) ?? value}`;
    },
    formatValue(value: string | number, name: string): string {
      return formatMetricValue(this.metricNames.find((v) => v === name) ?? '', value);
    },
    onUpdateStartDate(date: Date): void {
      if (this.aggregationInterval === 'P1W') {
        date = moment(date).startOf('isoWeek').toDate();
        this.onUpdateStopDate(moment(date).endOf('isoWeek').toDate());
      }
      this.$emit('update:start-date', date);
    },
    onUpdateStopDate(date: Date): void {
      let updatedDate = moment().toDate();
      if (date) {
        updatedDate = moment(date)
          .endOf(this.aggregationInterval === 'P1M' ? 'month' : 'day')
          .toDate();
      }

      this.$emit('update:stop-date', updatedDate);
    },
    now(): Moment {
      return moment();
    },
    updateAggregator(aggregator: MetricResolutionAggregator): void {
      this.$emit('update:aggregator', aggregator === null ? undefined : aggregator);
    },
  },
});
