























































import { CoreTreeNodeBarControlPathQuery } from '@/features/core/components/tree-node-bar-control/__generated__/CoreTreeNodeBarControlPathQuery';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<
        CoreTreeNodeBarControlPathQuery['treeNodes']['items'][number]['children']['items'][number]['children']['items']
      >,
      required: true,
    },
    noHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    subHeaders() {
      // TODO: get data from modbus once api is ready.
      if (this.noHeaders) {
        return ['Register 1', 'Register 2', 'Register 3', 'Register 4', 'Register 5', 'Register 6'];
      }

      return ['VL', 'RL', 'VL', 'RL', 'WA', 'Z'];
    },
  },
  methods: {
    openGraphModal(val: any) {
      this.$store.commit('setSelectedGraphObject', val);
      this.$store.commit('showAdvancedViewGraph', true);
    },
  },
});
