import { GetterTree, Module, MutationTree } from 'vuex';
import { RootStore } from '@/features/core/store';
import { CoreTreeNodeBarControlTreeNodeFragment } from '@/features/core/components/tree-node-bar-control/__generated__/CoreTreeNodeBarControlTreeNodeFragment';

type TreeNode = CoreTreeNodeBarControlTreeNodeFragment;

export type HeatingSystemsState = {
  showAdvancedView: boolean;
  showAdvancedViewGraph: boolean;
  selectedGraphObject: any;
  treeNodes: TreeNode[];
};

const state: HeatingSystemsState = {
  showAdvancedView: false,
  showAdvancedViewGraph: false,
  selectedGraphObject: null,
  treeNodes: [],
};
const getters: GetterTree<HeatingSystemsState, RootStore> = {
  showAdvancedView: (state) => state.showAdvancedView,
  showAdvancedViewGraph: (state) => state.showAdvancedViewGraph,
  treeNodes: (state) => state.treeNodes,
};
const mutations: MutationTree<HeatingSystemsState> = {
  showAdvancedView(state, value: boolean) {
    state.showAdvancedView = value;
  },
  showAdvancedViewGraph(state, value: boolean) {
    state.showAdvancedViewGraph = value;
  },
  setTreeNodes(state, value: TreeNode[]) {
    state.treeNodes = value;
  },
  setSelectedGraphObject(state, value: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    state.selectedGraphObject = value;
  },
};
const actions = {};

export const HeatingSystemsModule: Module<HeatingSystemsState, any> = {
  state,
  getters,
  mutations,
  actions,
};
