


















































import { defineComponent } from '@vue/composition-api';
import InputText from '@/features/ui/inputs/InputText.global.vue';
import InputCheckbox from '@/features/ui/inputs/InputCheckbox.global.vue';
import { mapGetters, mapState } from 'vuex';
import WizardButton from '@/components/clickables/WizardButton.global.vue';
import Pagination from '@/features/ui/pagination/Pagination.global.vue';
import FormMessage from '@/features/ui/form/FormMessage.global.vue';
import { ProjectBuilding } from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';

export default defineComponent<any, Record<string, unknown>, any, any, any>({
  components: {
    FormMessage,
    WizardButton,
    InputCheckbox,
    InputText,
    Pagination,
  },
  props: {},
  data() {
    return {
      search: '',
      currentPage: 1,
      columnsCount: 4,
      itemsPerColumn: 20,
      lastSelectedBuildingId: null,
      showSelectedBuildings: true,
    };
  },
  computed: {
    ...mapGetters(['buildingsWithElevators', 'activeLiegenschaftWithAssociatedBuildings', 'commentBuildingMap']),
    ...mapState({
      buildings: (state: any) => state.EnterpriseProjectCreateModule.newProjectData.buildings,
      activeStep2BuildingId: (state: any) => state.EnterpriseProjectCreateModule.activeStep2BuildingId,
      commentTabActiveBuildingId: (state: any) => state.EnterpriseProjectCreateModule.commentTabActiveBuildingId,
    }),
    filteredBuildings(): ProjectBuilding[] {
      const searchLower = this.search.toLowerCase();
      // Get the active main building and child buildings
      const activeBuildings = this.buildings.filter(
        (building: any) =>
          building.buildingId === this.activeStep2BuildingId || building.parentProperty === this.activeStep2BuildingId,
      );
      return activeBuildings.filter(
        (building: any) => !this.search || building.name.toLowerCase().includes(searchLower),
      );
    },
    columns(): number[] {
      return Array.from({ length: this.columnsCount }, (_, i) => i + 1);
    },
    itemsPerPage(): number {
      return this.columnsCount * this.itemsPerColumn;
    },
    totalPages(): number {
      return Math.ceil(this.filteredBuildings.length / this.itemsPerPage);
    },
    // Check if the main building has a comment in the commentBuildingMap (building id corresponds to the key)
    allBuildingsSelected() {
      return this.filteredBuildings.every((building: ProjectBuilding) => this.commentBuildingMap[building.buildingId]);
    },
  },
  watch: {
    selectedBuildings: {
      handler() {
        this.updateBuildingsWithComment();
      },
      deep: true,
    },
    activeStep2BuildingId: {
      handler() {
        this.currentPage = 1;
      },
      deep: true,
    },
    commentBuildingMap: {
      handler() {
        this.refreshList();
      },
      deep: true,
    },
  },
  async created() {
    this.$store.commit('setCommentTabActiveBuildingId', this.activeStep2BuildingId);
  },
  methods: {
    setCommentActiveBuilding(id: number) {
      this.$store.commit('setCommentTabActiveBuildingId', id);
    },
    updateSelectedBuildings() {
      this.selectedBuildings = [];
      // Get the active building
      const activeBuilding = this.buildings.find(
        (building: ProjectBuilding) => building.buildingId === this.activeStep2BuildingId,
      );
      const tempSelectedBuildings = [];
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        if (this.filteredBuildings[i].comment) {
          tempSelectedBuildings[this.filteredBuildings[i].buildingId] =
            this.filteredBuildings[i].comment === activeBuilding.comment;
        }
      }
      this.selectedBuildings = tempSelectedBuildings;
    },
    updateBuildingsWithComment() {
      const buildingIds = [];
      for (let i = 0; i < this.filteredBuildings.length; i++) {
        // Get ids of selected buildings
        if (this.selectedBuildings[this.filteredBuildings[i].buildingId]) {
          buildingIds.push(this.filteredBuildings[i].buildingId);
        }
        this.$store.commit('setCommentBuildingIds', { buildingIds, value: true });
      }
    },
    getCurrentPageBuildingsForColumn(column: number): ProjectBuilding[] {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage + (column - 1) * this.itemsPerColumn;
      const endIndex = startIndex + this.itemsPerColumn;
      return this.filteredBuildings.slice(startIndex, endIndex);
    },
    selectAllFilteredBuildings() {
      this.showSelectedBuildings = false;
      const buildingIds = this.filteredBuildings.map((building: ProjectBuilding) => building.buildingId);
      this.$store.commit('setCommentBuildingIds', { buildingIds, value: true });
      this.showSelectedBuildings = true;
    },
    deselectAllFilteredBuildings() {
      this.showSelectedBuildings = false;
      const buildingIds = this.filteredBuildings.map((building: ProjectBuilding) => building.buildingId);
      this.$store.commit('setCommentBuildingIds', { buildingIds, value: false });
      this.showSelectedBuildings = true;
    },
    onBuildingClick(event: MouseEvent, buildingId: number) {
      this.$forceUpdate();
    },
    getListItemSubText(building: ProjectBuilding): string {
      if (this.commentBuildingMap[building.buildingId]) {
        return 'ausgewählt';
      }
      if (building.buildingId === this.commentTabActiveBuildingId) {
        return 'in Bearbeitung';
      }
      if (building.comment) {
        return 'ändern';
      }
      return 'offen';
    },
    refreshList() {
      this.showSelectedBuildings = false;
      this.showSelectedBuildings = true;
    },
  },
});
