












































import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { MetricPoint, MetricPointExtra, Metrics } from '@/features/core/util/metrics';
import { MetricDescriptorInput, MetricResolutionAggregator, MetricResolutionInput, SpotRole } from '@/types/iot-portal';
import { isDef } from '@/util/lang';
import { ArrayProp, DateProp, EnumProp, StringProp } from '@/util/prop-decorators';
import { FetchResult } from 'apollo-link';
import { groupBy, partition } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Debounce } from '@/util/debounce-decorator';
import { DeviceRoleAggregationMetric } from '../../model';
import { formatMetricName, getMetricUnitName } from '../../util/metric-formatters';
import aggregationQuery from './aggregation.gql';
import historyQuery from './history.gql';
import latestMetricsPointsQuery from './latest-metrics-points.gql';
import metricsSubscription from './metrics.gql';
import spotQuery from './spot.gql';
import SpotMetricsPanelControlTable from './SpotMetricsPanelControlTable.vue';
import {
  CoreSpotMetricsPanelControlAggregationQuery,
  CoreSpotMetricsPanelControlAggregationQueryVariables,
} from './__generated__/CoreSpotMetricsPanelControlAggregationQuery';
import {
  CoreSpotMetricsPanelControlHistoryQuery,
  CoreSpotMetricsPanelControlHistoryQueryVariables,
  CoreSpotMetricsPanelControlHistoryQuery_history_first_metrics,
} from './__generated__/CoreSpotMetricsPanelControlHistoryQuery';
import {
  CoreSpotMetricsPanelControlLatestMetricsPointsQuery,
  CoreSpotMetricsPanelControlLatestMetricsPointsQueryVariables,
} from './__generated__/CoreSpotMetricsPanelControlLatestMetricsPointsQuery';
import {
  CoreSpotMetricsPanelControlMetricsSubscription,
  CoreSpotMetricsPanelControlMetricsSubscriptionVariables,
} from './__generated__/CoreSpotMetricsPanelControlMetricsSubscription';
import {
  CoreSpotMetricsPanelControlSpotQuery,
  CoreSpotMetricsPanelControlSpotQueryVariables,
  CoreSpotMetricsPanelControlSpotQuery_spot_first_customer_attributeDefinitions,
} from './__generated__/CoreSpotMetricsPanelControlSpotQuery';
import { calculatedMetrics, checkMetricName } from '../../util/metric-calculations';
import { ApolloQueryResult } from 'apollo-client';

type HistoryMetric = CoreSpotMetricsPanelControlHistoryQuery_history_first_metrics;

@Component({
  components: { SpotMetricsPanelControlTable },
  apollo: {
    spot: {
      query: spotQuery,
      fetchPolicy: 'network-only',
      variables(this: SpotMetricsPanelControl): CoreSpotMetricsPanelControlSpotQueryVariables {
        return { spotId: this.spotId };
      },
    },
    latestMetricsPoints: {
      query: latestMetricsPointsQuery,
      skip(): boolean {
        return this.stopDate === undefined;
      },
      variables(this: SpotMetricsPanelControl): CoreSpotMetricsPanelControlLatestMetricsPointsQueryVariables {
        return { spotId: this.spotId, ...this.descriptorDates, includeOutsideTemperature: true };
      },
      manual: true,
      result(
        this: SpotMetricsPanelControl,
        { data }: FetchResult<CoreSpotMetricsPanelControlLatestMetricsPointsQuery>,
      ) {
        this.latestMetrics = Metrics.create(data?.spots.first.metrics.map(({ latest }) => latest) ?? []);
      },
    },
    history: {
      query: historyQuery,
      fetchPolicy: 'no-cache',
      skip(this: SpotMetricsPanelControl): boolean {
        const selectedHistoryMetric = this.selectedHistoryMetric
          ? checkMetricName(this.selectedHistoryMetric)
          : undefined;
        return selectedHistoryMetric === undefined || this.metrics.all[selectedHistoryMetric] === undefined;
      },
      variables(this: SpotMetricsPanelControl): CoreSpotMetricsPanelControlHistoryQueryVariables {
        return {
          spotId: this.spotId,
          descriptor: {
            name: this.selectedHistoryMetric?.replace('_calc', ''),
            take: this.historySize,
            skip: this.historyPage * this.historySize,
            ...this.descriptorDates,
          },
        };
      },
      result(
        this: SpotMetricsPanelControl,
        { data }: ApolloQueryResult<CoreSpotMetricsPanelControlHistoryQuery>,
      ): void {
        // check if its a calculated selected history metric
        let calculatedHistoryMetrics: MetricPointExtra[] = [];
        const attributeDefinitions = this.spot?.first.customer.attributeDefinitions.items;
        if (this.selectedHistoryMetric?.includes('_calc')) {
          // attach calculated history metrics
          const metrics = data.history.first.metrics;
          metrics.map((metric: HistoryMetric) => {
            if (attributeDefinitions && metric.__typename === 'ContinuousMetric') {
              const points: MetricPointExtra[] = metric.points as MetricPointExtra[];
              calculatedHistoryMetrics = calculatedMetrics(points, attributeDefinitions, this.objectIds);
            }
          });

          data.history.first.metrics.forEach((metric) => {
            metric.name = `${metric.name}_calc`;
            metric.points = calculatedHistoryMetrics as HistoryMetric['points'];
          });
        }

        this.history = data.history;
      },
    },
    aggregation: {
      query: aggregationQuery,
      fetchPolicy: 'no-cache',
      skip(this: SpotMetricsPanelControl): boolean {
        return (
          this.selectedHistoryMetric === undefined ||
          this.metrics.all[this.selectedHistoryMetric] === undefined ||
          !this.aggregationMetricSet.has(this.selectedHistoryMetric)
        );
      },
      variables(this: SpotMetricsPanelControl): CoreSpotMetricsPanelControlAggregationQueryVariables {
        return {
          spotId: this.spotId,
          descriptor: {
            name: this.selectedHistoryMetric,
            resolution: this.aggregationResolution,
            take: this.historySize,
            skip: this.historyPage * this.historySize,
            ...this.descriptorDates,
          },
        };
      },
    },
    $subscribe: {
      metrics: {
        query: metricsSubscription,
        variables(this: SpotMetricsPanelControl): CoreSpotMetricsPanelControlMetricsSubscriptionVariables {
          return { spotId: this.spotId };
        },
        result(this: SpotMetricsPanelControl, { data }: FetchResult<CoreSpotMetricsPanelControlMetricsSubscription>) {
          if (data) {
            this.metrics.pushLatest(data.point);
          }
        },
      },
    },
  },
  data() {
    return {
      spot: undefined,
      history: undefined,
      aggregation: undefined,
      liveMetrics: new Metrics(),
      latestMetrics: new Metrics(),
      selectedHistoryMetric: undefined,
    };
  },
})
export default class SpotMetricsPanelControl extends Mixins(DeviceRoleMapMixin) {
  @ArrayProp(() => [])
  private readonly selectedMetrics!: string[];

  @ArrayProp(() => [])
  private readonly selectableMetrics!: string[];

  @DateProp()
  private readonly startDate?: Date;

  @DateProp()
  private readonly stopDate?: Date;

  @StringProp()
  private readonly spotId!: string;

  @StringProp()
  private readonly aggregationInterval?: Duration;

  @EnumProp(false, ...Object.keys(MetricResolutionAggregator))
  private readonly aggregator?: MetricResolutionAggregator;

  private readonly spot?: CoreSpotMetricsPanelControlSpotQuery['spot'];

  private history?: CoreSpotMetricsPanelControlHistoryQuery['history'];
  private readonly aggregation?: CoreSpotMetricsPanelControlAggregationQuery['aggregation'];
  private liveMetrics!: Metrics;
  private latestMetrics!: Metrics;

  private historyPage = 0;
  private historySize = 10;
  private selectedHistoryMetric?: string;
  private more = false;

  private get loading(): boolean {
    return this.$apollo.loading;
  }

  private get descriptorDates(): Pick<MetricDescriptorInput, 'start' | 'stop'> {
    const timezoneOffset = moment(this.startDate).utcOffset();

    const start = moment(this.startDate).utc().add(timezoneOffset, 'minutes').startOf('day').toDate();

    const stop = this.stopDate === undefined ? undefined : moment(this.stopDate).add(1, 'day').startOf('day').toDate();

    return { start, stop };
  }

  private get metrics(): Metrics {
    return this.stopDate === undefined ? this.liveMetrics : this.latestMetrics;
  }

  private get metricRows(): MetricPoint[] {
    const metricRows = Object.values(this.metrics.all).filter(isDef);

    const gdprMetrics = this.getGdprMetricPoints(metricRows, 'asc');

    if (this.selectableMetrics && this.selectableMetrics.length !== 0) {
      return gdprMetrics.filter((metric) => this.selectableMetrics.includes(metric.name));
    }

    return gdprMetrics.sort(({ name: name1 }, { name: name2 }) => {
      const label1 =
        this.spot?.first.metricConfigurations.find(({ name }) => name === name1)?.label ??
        formatMetricName(name1 ?? '');
      const label2 =
        this.spot?.first.metricConfigurations.find(({ name }) => name === name2)?.label ??
        formatMetricName(name2 ?? '');

      return label1.localeCompare(label2);
    });
  }

  // computed property to retrieve attribute definitions based on selected spot and customer.
  private get attributeDefinitions():
    | CoreSpotMetricsPanelControlSpotQuery_spot_first_customer_attributeDefinitions
    | undefined {
    return this.spot?.first.customer?.attributeDefinitions;
  }

  private get propertyId(): string | undefined {
    return this.spot?.first.property?.node?.id;
  }

  private get objectIds(): string[] {
    const result: string[] = [];
    if (this.$router.currentRoute.params.treeNodeId) {
      result.push(this.$router.currentRoute.params.treeNodeId);
    }
    if (this.propertyId) {
      result.push(this.propertyId);
    }
    if (this.spot?.first.id) {
      result.push(this.spot.first.id);
    }
    return result;
  }

  private get importantMetricRows(): MetricPoint[] {
    let importantMetricRows = this.metricRows.filter(({ name }) => this.isImportantMetric(name));

    // get attributes that matches available metric names
    const attributeDefinitions = this.attributeDefinitions?.items.filter((attribute) => {
      const calculations =
        attribute.configuration.__typename === 'CalculationAttributeConfiguration' &&
        attribute.configuration.calculations;
      return calculations && this.importantMetricNames.includes(calculations.metricName as string);
    });

    // add calculated metric if available
    if (attributeDefinitions && this.objectIds.length > 0) {
      importantMetricRows = importantMetricRows.concat(
        calculatedMetrics(importantMetricRows, attributeDefinitions, this.objectIds),
      );
    }

    return importantMetricRows;
  }

  private get moreMetricRows(): MetricPoint[] {
    return this.metricRows.filter(({ name }) => !this.isImportantMetric(name));
  }

  private get metricNames(): string[] {
    return Object.keys(this.metrics.all);
  }

  private get importantMetricNames(): string[] {
    return this.deviceRoleMap[this.spot?.first.role ?? '']?.importantMetricNames ?? [];
  }

  private get aggregationMetricSet(): Set<string> {
    return new Set(
      this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map(({ name }) => name) ?? [],
    );
  }

  private get aggregationMetricMap(): Map<string, DeviceRoleAggregationMetric> {
    return new Map(
      this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map((metric) => [metric.name, metric]),
    );
  }

  private get aggregationResolution(): MetricResolutionInput | undefined {
    const metric = this.aggregationMetricMap.get(this.selectedHistoryMetric ?? '');
    if (metric === undefined) {
      return undefined;
    }

    return {
      intervalLength: this.aggregationInterval ?? 'PT1M',
      timeSource: metric.timeSource,
      aggregator: this.aggregator,
    };
  }

  private get aggregationMetric():
    | CoreSpotMetricsPanelControlAggregationQuery['aggregation']['first']['metrics'][number]
    | undefined {
    return this.aggregation?.first.metrics.find(({ name }) => name === this.selectedHistoryMetric);
  }

  private get defaultMetricNames(): string[] {
    const defaultMetricNames = this.deviceRoleMap[this.spot?.first.role ?? '']?.defaultMetricNames ?? [];

    const filteredDefaultMetricNames = defaultMetricNames.filter((defaultMetricName) =>
      this.metricNames.includes(defaultMetricName),
    );

    return this.selectableMetrics.length === 0
      ? filteredDefaultMetricNames
      : filteredDefaultMetricNames.filter((metricName) => this.selectableMetrics.includes(metricName));
  }

  private get showNonStandardMetrics(): boolean {
    return this.spot?.first.role !== 'HEATING_CONTROL';
  }

  @Watch('metricNames')
  @Debounce(500)
  private updateMetricNames(): void {
    this.checkAvailableOnGraphMetrics();
    if (this.defaultMetricNames.length !== 0) {
      this.$emit('update:selected-metrics', this.defaultMetricNames);
    }
  }

  private hasMetricAggregators(metricName: string): boolean {
    return (this.aggregationMetricMap.get(metricName)?.aggregators ?? []).length > 0;
  }

  private isImportantMetric(name: string): boolean {
    name = checkMetricName(name);
    return this.importantMetricNames.includes(name) || this.importantMetricNames.includes(name.replace(/\d+$/, ''));
  }

  private checkAvailableOnGraphMetrics(): void {
    const availableStandardMetrics = this.importantMetricRows.filter(
      ({ name }) => this.isImportantMetric(name) || this.hasMetricAggregators(name),
    );
    const availableNonStandardMetrics = this.moreMetricRows.filter(({ name }) => this.hasMetricAggregators(name));
    const availableOnGraphMetrics = [...availableStandardMetrics, ...availableNonStandardMetrics].map(
      ({ name }) => name,
    );
    this.$emit('update:available-on-graph-metrics', availableOnGraphMetrics);
  }

  private onHistoryClick(name: string): void {
    this.selectedHistoryMetric = this.selectedHistoryMetric !== name ? name : undefined;
    this.historyPage = 0;
  }

  private selectMetrics(event: boolean, name: string): void {
    const selectedMetrics = this.selectedMetrics;
    if (this.selectedMetrics.length !== 0 && getMetricUnitName(this.selectedMetrics[0]) !== getMetricUnitName(name)) {
      selectedMetrics.length = 0;
    }
    if (event) {
      selectedMetrics.push(name);
    }
    if (!event) {
      selectedMetrics.splice(selectedMetrics.indexOf(name), 1);
    }

    this.$emit('update:selected-metrics', selectedMetrics);
  }

  private getGdprMetricPoints(metricPoints: MetricPoint[], order: 'asc' | 'desc'): MetricPoint[] {
    if (
      ![SpotRole.HEAT_COST_ALLOCATOR, SpotRole.HEAT_METER_COUNTER, SpotRole.WATER_METER_HOT].includes(
        this.spot?.first.role ?? SpotRole.UNKNOWN,
      )
    ) {
      return metricPoints;
    }
    const now = DateTime.utc();

    const [newerThan2Weeks, olderThan2Weeks] = partition(
      metricPoints,
      ({ time }) => DateTime.fromISO(time).plus({ weeks: 2 }) >= now,
    );

    const pointsGroupedByYearMonth = groupBy(
      olderThan2Weeks,
      ({ time }) => `${DateTime.fromISO(time).year}-${DateTime.fromISO(time).month}`,
    );

    const filteredPointsOlderThan2Weeks = Object.values(pointsGroupedByYearMonth).flatMap((points) =>
      this.filter1thAnd16th(points),
    );

    // sort the points in descending order by time when no aggregation is set
    if (order === 'asc') {
      return [...newerThan2Weeks, ...filteredPointsOlderThan2Weeks].sort(
        (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime(),
      );
    }

    return [...newerThan2Weeks, ...filteredPointsOlderThan2Weeks].sort(
      (a, b) => new Date(b.time).getTime() - new Date(a.time).getTime(),
    );
  }

  private filter1thAnd16th(metricPoints: MetricPoint[]): MetricPoint[] {
    const firstOfMonth = metricPoints.find(({ time }) => {
      return DateTime.fromISO(time).hasSame(DateTime.fromISO(time).startOf('month'), 'day');
    });

    const sixteenthOfMonth = metricPoints.find(({ time }) => {
      const date = DateTime.fromISO(time);

      return DateTime.fromISO(time).hasSame(DateTime.utc(date.year, date.month, 16), 'day');
    });

    if (firstOfMonth === undefined && sixteenthOfMonth === undefined) {
      return [];
    }

    return [firstOfMonth, sixteenthOfMonth].filter(isDef);
  }
}
