import {
  HeatingSystem,
  HeatingSystemMetadataResponse,
  MetadataKeys,
} from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';

export function removeLabelFromName(measurementName: string | null, metricLabel: string): string {
  if (!measurementName) return 'Unknown name';

  // match the metricLabel from the end of the measurementName
  const pattern = new RegExp(`${metricLabel}$`, 'g');

  return measurementName.replace(pattern, '').trim();
}

export function getHeatingSystemsComponents(heatingSystem: HeatingSystem): Map<string, string> {
  const heatingSystemComponents = new Map<string, string>(
    heatingSystem.components.heatingSystemMeasurementGroups.flatMap(({ heatingSystemMeasurements }) => {
      return heatingSystemMeasurements.map((measurement) => {
        return [measurement.spotId, removeLabelFromName(measurement.measurementName, measurement.metricLabel)];
      });
    }),
  );

  return heatingSystemComponents;
}

type ComponentType = {
  component: string;
  type: string;
};

export function getHeatingSystemsComponentsWithTypes(heatingSystem: HeatingSystem): Map<string, ComponentType> {
  const heatingSystemComponents = new Map<string, ComponentType>(
    heatingSystem.components.heatingSystemMeasurementGroups.flatMap(
      ({ heatingSystemMeasurements, systemComponentTypeName }) => {
        return heatingSystemMeasurements.map((measurement) => {
          const component = removeLabelFromName(measurement.measurementName, measurement.metricLabel);
          return [measurement.spotId, { component, type: systemComponentTypeName }];
        });
      },
    ),
  );

  return heatingSystemComponents;
}

export enum MetadataStatus {
  MANDATORY_INFORMATION_COMPLETE = 'MANDATORY_INFORMATION_COMPLETE',
  MANDATORY_INFORMATION_INCOMPLETE = 'MANDATORY_INFORMATION_INCOMPLETE',
}

// eslint-disable-next-line complexity
export function isComponentMetadataCompleted(component: HeatingSystemMetadataResponse): boolean {
  const metadata = component.metadata as MetadataKeys;
  switch (component.componentType) {
    case 'BOILER':
      if (typeof metadata.condensing === 'boolean') {
        return true;
      }
      return false;
    case 'WATER_HEATING':
    case 'PORTABLE_WATER_HEATING':
      if (
        !metadata.circulation_target_temp ||
        !metadata.circulation_temperature_tolerance ||
        !metadata.potable_water_target_temp ||
        !metadata.potable_water_temperature_tolerance ||
        !metadata.type_of_pwh
      ) {
        return false;
      }
      return true;
    case 'HEATING_CIRCUIT':
      return metadata.control_parameter.type ? true : false;
    case 'TELE_HEATING':
      return metadata.flow_temperature_control_parameter.type && metadata.return_temperature_control_parameter.type
        ? true
        : false;
    default:
      return true;
  }
}
