



















import { Component, Vue } from 'vue-property-decorator';
import { StringProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { AddGK40HeizungsprofiConfigurationInput } from '@/types/iot-portal';
import { AppCustomerAddVendorApiWizardControlVendorApiFragment } from '../__generated__/AppCustomerAddVendorApiWizardControlVendorApiFragment';
import {
  AppCustomerAddGK40HeizungsprofiConfigMutation,
  AppCustomerAddGK40HeizungsprofiConfigMutationVariables,
} from './__generated__/AppCustomerAddGK40HeizungsprofiConfigMutation';
import addGK40HeizungsprofiConfiguration from './add-gk40-heizungsprofi.gql';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';

@Component
export default class GK40Heizungsprofi extends Vue {
  private readonly isNonEmptyString = isNonEmptyString;

  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private async addGK40HeizungsprofiProvider(
    input: AddGK40HeizungsprofiConfigurationInput,
  ): Promise<AppCustomerAddVendorApiWizardControlVendorApiFragment | void> {
    try {
      const { data } = await this.$apollo.mutate<
        AppCustomerAddGK40HeizungsprofiConfigMutation,
        AppCustomerAddGK40HeizungsprofiConfigMutationVariables
      >({
        mutation: addGK40HeizungsprofiConfiguration,
        variables: { input: { ...input, customerId: this.customerId, label: input.label || '' } },
      });

      if (!data) {
        throw new Error('Der Datenanbieter konnte nicht hinzugefügt werden!');
      }

      this.$store.dispatch('createEnerIQConnection', {
        customerId: this.customerId,
      });

      return data.addGK40HeizungsprofiConfiguration.gk40HeizungsprofiConfiguration;
    } catch (error) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Der Datenanbieter konnte nicht hinzugefügt werden.', class: 'error' }],
      });
    }
  }
}
