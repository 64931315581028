












































import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { EditGK40HeizungsprofiConfigurationInput, TreeNodeType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { isString } from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import editMutation from './edit.gql';
import removeMutation from './remove.gql';
import query from './view.gql';
import {
  AppCustomerGK40HeizungsprofiConfigurationSettingsViewEditMutation,
  AppCustomerGK40HeizungsprofiConfigurationSettingsViewEditMutationVariables,
} from './__generated__/AppCustomerGK40HeizungsprofiConfigurationSettingsViewEditMutation';
import {
  AppCustomerGK40HeizungsprofiConfigurationSettingsViewRemoveMutation,
  AppCustomerGK40HeizungsprofiConfigurationSettingsViewRemoveMutationVariables,
} from './__generated__/AppCustomerGK40HeizungsprofiConfigurationSettingsViewRemoveMutation';
import { ApolloQueryResult } from 'apollo-client/core/types';
import {
  AppCustomerGK40HeizungsprofiConfigurationSettingsViewQuery,
  AppCustomerGK40HeizungsprofiConfigurationSettingsViewQueryVariables,
  AppCustomerGK40HeizungsprofiConfigurationSettingsViewQuery_customers_first_gk40HeizungsprofiConfigurations_first_treeNodes_items,
} from './__generated__/AppCustomerGK40HeizungsprofiConfigurationSettingsViewQuery';

type FormData = Pick<EditGK40HeizungsprofiConfigurationInput, 'consent' | 'label' | 'treeNodeIds'>;

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: SettingsView): AppCustomerGK40HeizungsprofiConfigurationSettingsViewQueryVariables {
        return {
          customerId: this.customerId,
          vendorApiId: this.vendorApiId,
        };
      },
      result(
        this: SettingsView,
        { data }: ApolloQueryResult<AppCustomerGK40HeizungsprofiConfigurationSettingsViewQuery>,
      ): void {
        if (data != undefined) {
          this.treeNodes = data.customers.first.gk40HeizungsprofiConfigurations.first.treeNodes.items;
        }
      },
    },
  },
  data() {
    return { customers: undefined, treeNodes: undefined };
  },
})
export default class SettingsView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;
  private implicitBoolean = true;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private readonly customers?: AppCustomerGK40HeizungsprofiConfigurationSettingsViewQuery['customers'];

  private treeNodes?: AppCustomerGK40HeizungsprofiConfigurationSettingsViewQuery_customers_first_gk40HeizungsprofiConfigurations_first_treeNodes_items[];

  private pluckIds<T>(objects: { id: T }[] | null): T[] {
    return objects === null ? [] : objects.map(({ id }) => id);
  }

  private get rootIds(): string[] {
    return this.customerId ? [this.customerId] : [];
  }

  private get consentDescription(): string {
    return `Hiermit bestätige ich, dass die Intgeration zwischen der GK4.0 Plattform und Allpas aktiviert werden soll. Es sollen alle Daten anhand der gesetzten Konfiguration übermittelt oder empfangen werden. Bitte speichern Sie die neuen Einstellungen.`;
  }

  private consentStatusText(consent: boolean): string {
    if (consent) {
      return `Die Integration wurde aktiviert. Es werden anhand der vorgenommenen Konfiguration Dateien importiert & Verbrauchsdaten bereitgestellt. Wenn Sie die Integration deaktivieren möchten, dann entfernen Sie den Haken unter Einverständniserklärung und speichern die neuen Einstellungen.`;
    }

    return `Die Integration wurde deaktiviert. Es werden aktuell keine Daten importiert oder Verbrauchsdaten bereitgestellt.`;
  }

  private async edit(input: FormData): Promise<void> {
    const gk40HeizungsprofiConfigurationId = this.customers?.first.gk40HeizungsprofiConfigurations.first?.id;
    if (gk40HeizungsprofiConfigurationId === undefined) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerGK40HeizungsprofiConfigurationSettingsViewEditMutation,
      AppCustomerGK40HeizungsprofiConfigurationSettingsViewEditMutationVariables
    >({
      mutation: editMutation,
      variables: {
        input: {
          gk40HeizungsprofiConfigurationId,
          ...input,
          label: input.label || '',
        },
      },
    });

    if (!data) {
      throw new Error('Die Konfiguration des Verbrauchsdatenaustausch konnte nicht gespeichert werden');
    }

    if (!data.editGK40HeizungsprofiConfiguration.gk40HeizungsprofiConfiguration) {
      throw new Error(
        `Die Konfiguration des Verbrauchsdatenaustausch konnte nicht gespeichert werden: ${data.editGK40HeizungsprofiConfiguration.gk40HeizungsprofiConfiguration}`,
      );
    }

    this.$store.dispatch('updateEnerIQConnection', {
      customerId: this.customerId,
      enabled: true,
      consent: input.consent,
      treeNodeIds: input.treeNodeIds,
    });

    const text = `Die Konfiguration des Verbrauchsdatenaustausch wurde gespeichert`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });
  }

  private async remove(): Promise<void> {
    const gk40HeizungsprofiConfigurationId = this.customers?.first.gk40HeizungsprofiConfigurations.first?.id;
    if (gk40HeizungsprofiConfigurationId === undefined) {
      return;
    }

    if (!window.confirm('Die Verbrauchsdatenaustausch Konfiguration wurde entfernt')) {
      return;
    }

    const { data } = await this.$apollo.mutate<
      AppCustomerGK40HeizungsprofiConfigurationSettingsViewRemoveMutation,
      AppCustomerGK40HeizungsprofiConfigurationSettingsViewRemoveMutationVariables
    >({
      mutation: removeMutation,
      variables: { input: { gk40HeizungsprofiConfigurationId } },
    });

    if (!data) {
      throw new Error('Die Verbrauchsdatenaustausch Konfiguration konnte nicht entfernt werden');
    }

    this.$store.dispatch('deleteEnerIQConnection', this.customerId);

    const text = `Die Verbrauchsdatenaustausch Konfiguration wurde entfernt`;
    this.ADD_TOAST_MESSAGES({ messages: [{ text }] });

    this.$router.push({ name: 'AppCustomer/Customer/VendorApis' });
  }

  private isNonEmptyString(value: unknown): boolean {
    return isString(value) && value.length > 0;
  }

  private get consumptionTreeNodeTypes(): TreeNodeType[] {
    return [TreeNodeType.Property, TreeNodeType.Directory, TreeNodeType.RootDirectory];
  }

  private get selectableTreeNodeTypes(): TreeNodeType[] {
    return [TreeNodeType.Property, TreeNodeType.RootDirectory, TreeNodeType.Directory];
  }
}
